html,
body {
  font-family: 'Inconsolata', monospace;
  height: 100%;
}
a {
  color: #3b5998;
}
footer {
  background-color: #f7f7f7;
}
.container {
  width: auto;
  max-width: 900px;
  padding: 0 15px;
}
